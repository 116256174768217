// External Imports
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";
import {
  Box,
  Grid,
  Pagination,
  Container,
  Divider,
  CircularProgress,
} from "@mui/material";
import debounce from "lodash.debounce";
// Components
import {
  DocsCallToAction,
  PanelWrapper,
  Breadcrumb,
  useGuideSidebar,
  SkeletonCard,
  SEO,
} from "../../../exports/components.exports";
// Config
import { colors } from "../../../exports/assets.exports";
import { urlRoutes } from "../../../exports/constants.exports";
import AllGuides from "./AllGuides";

/**
 * @constant query for strapi
 */
export const query = graphql`
  query guidePageQuery($skip: Int!, $limit: Int!) {
    allStrapiGuide(
      sort: { fields: updated_at, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          Title
          Description
          Content
          FeatureImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          Slug
          updated_at(fromNow: true)
          Author {
            name
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          Category {
            name
            slug
          }
          SimilarGuides {
            Slug
            Title
          }
        }
      }
    }
    allStrapiCategory {
      edges {
        node {
          slug
          name
          articles {
            id
          }
        }
      }
    }
    allGuides: allStrapiGuide {
      edges {
        node {
          id
          Title
          Description
          Content
          FeatureImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          Slug
          updated_at(fromNow: true)
          Author {
            name
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          Category {
            name
            slug
          }
          SimilarGuides {
            Slug
            Title
          }
        }
      }
    }
  }
`;

/**
 * @function GuidesPage
 * @returns the guides page content
 */
const GuidesPage = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const guides = data.allStrapiGuide.edges;
  const { currentPage, numPages } = pageContext;
  const allGuides = data.allGuides.edges;
  const [searchQuery, setSearchQuery] = useState("");
  const searchFields = ["Title", "Description"]; // the keys on allGuides.edges.node
  const [filteredGuides, setFilteredGuides] = useState<any>();
  const [isSearching, setIsSearching] = useState(false);
  const DrawerSideBar = useGuideSidebar(data);

  const seo = {
    metaTitle: `Swiftcase - All Guides and Articles - Page ${currentPage}`,
    metaDescription:
      "A paginated list of all of Swiftcase's guides and articles to help you use our software.",
  };

  /**
   * @function handlePaginationChange
   * @param value the page number to navigate to
   * @returns the navigation from gatsby
   */
  const handlePaginationChange = (_: any, value: number) => {
    if (value === 1) {
      navigate(`${urlRoutes.GUIDES}/`);
    } else {
      navigate(`${urlRoutes.GUIDES}/${value}/`);
    }
  };

  /**
   * @function debounceCallback debounced callback
   * to set isSearching to true
   * sets the query string state
   */
  const debounceCallback = debounce((query: string) => {
    setIsSearching(true);
    setSearchQuery(query);
  }, 1000);

  /**
   * @function handleSearchCallback
   * @param query passed back up from the call to action (search input field)
   */
  const handleSearchCallback = (query: string) => {
    debounceCallback(query);
  };

  /**
   * @function debouncedFilter
   * a debounced function to filter the results
   * filters allGuides then uses the searchFields constant (an array of the key's on the object)
   * to see if the searchQuery string is included on any of those fields
   */
  const debouncedFilter = useCallback((searchQuery) => {
    let filterSearchGuides = allGuides.filter((guide: any) =>
      searchFields.some((key: string) =>
        guide.node[key].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setIsSearching(false);
    setFilteredGuides(filterSearchGuides);
  }, []);

  /**
   * @function useEffect to handle the search query and debounce call to filter results
   * if empty string empty the filtered results, set isSearching to false
   * else call the debounced filter method
   */
  useEffect(() => {
    if (searchQuery === "") {
      setFilteredGuides(undefined);
      setIsSearching(false);
    } else {
      debouncedFilter(searchQuery);
    }
  }, [searchQuery, debouncedFilter]);

  return (
    <Fragment>
      <SEO seo={seo} />
      <DocsCallToAction
        handleSearchCallback={handleSearchCallback}
        showSearchBar
      />
      <Box sx={{ backgroundColor: colors.swiftcaseWebsiteLightGrey }}>
        <Container
          maxWidth="lg"
          sx={{ backgroundColor: colors.swiftcaseWebsiteLightGrey }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginY: "2rem",
            }}
          >
            <Breadcrumb currentPage="Guides" />
            <Box>
              <Box>{DrawerSideBar}</Box>
            </Box>
          </Box>
        </Container>
        <Divider />
        <PanelWrapper
          contentWidth="lg"
          minHeight="10vh"
          backgroundColor={colors.swiftcaseWebsiteLightGrey}
          centered
        >
          {!isSearching ? (
            <Grid
              container
              className="guide-card-grid"
              spacing={4}
              justifyContent="flex-start"
            >
              {filteredGuides?.length === 0 ? (
                <Grid
                  item
                  xs={12}
                  className="guide-category__no-blogs"
                  sx={{ justifyContent: "center" }}
                >
                  <SkeletonCard
                    noAction
                    alternateMessage={`No search result's matched; "${searchQuery}"`}
                  />
                </Grid>
              ) : (
                <AllGuides
                  guides={
                    searchQuery !== "" && filteredGuides
                      ? filteredGuides
                      : guides
                  }
                />
              )}
              {searchQuery === "" && (
                <Grid
                  item
                  className="guide-grid__grid-item"
                  xs={12}
                  sx={{ marginTop: "2rem" }}
                >
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={numPages}
                    page={currentPage}
                    boundaryCount={2}
                    onChange={handlePaginationChange}
                    className="guide-grid__pagination"
                    sx={{
                      width: "fit-content",
                      marginX: "auto",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ color: colors.swiftcaseMediumBlue }} />
            </Box>
          )}
        </PanelWrapper>
      </Box>
    </Fragment>
  );
};

export default GuidesPage;
